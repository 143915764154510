// Global/Shared Sass
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import './assets/scss/main.scss';

import NewAward from 'features/award/container/new-award';
import Award from 'features/award/pages/award';
import AwardDetail from 'features/award/pages/award-detail';
import AwardList from 'features/award/pages/award-list';
import BankProvider from 'features/credit/pages/bank';
import BankProviderDetail from 'features/credit/pages/provider-detail';
import BankProviderList from 'features/credit/pages/provider-list';
import EmergencyReport from 'features/emergency-report/pages/emergency-report';
import EmergencyReportList from 'features/emergency-report/pages/emergency-report-list';
import EmergencyReportLandingPage from 'features/emergency-report/pages/landing-page';
import Feedback from 'features/feedback/pages/feedback';
import FeedbackDetail from 'features/feedback/pages/feedback-detail';
import FeedbackList from 'features/feedback/pages/feedback-list';
import { ROLES } from 'features/model/role';
import NotificationDetail from 'features/notification/pages/notification-detail';
import NotificationList from 'features/notification/pages/notification-list';
import NotificationNew from 'features/notification/pages/notification-new';
import NewOwner from 'features/owner/container/new-owner';
import OwnerDetail from 'features/owner/pages/owner-detail';
import OwnerList from 'features/owner/pages/owner-list';
import Passanger from 'features/passanger/pages/passanger';
import PassangerDetail from 'features/passanger/pages/passanger-detail';
import PassangerLandingPage from 'features/passanger/pages/passanger-landing-page';
import PassangerList from 'features/passanger/pages/passanger-list';
import Profile from 'features/profile/pages/profile-page';
import ProviderOwnerList from 'features/provider/pages/provider-owner-list';
import ProviderVehicle from 'features/provider/pages/provider-vehicle';
import PublicProviderLandingPage from 'features/public-provider/pages/public-provider-landing-page';
import PublicProviderList from 'features/public-provider/pages/public-provider-list';
import PublicProvider from 'features/public-provider/pages/public-providex';
import PublicVehicle from 'features/public-vehicle/pages/public-vehicle';
import PublicVehicleLandingPage from 'features/public-vehicle/pages/public-vehicle-landing-page';
import PublicVehicleList from 'features/public-vehicle/pages/public-vehicle-list';
import CancellationReasons from 'features/reports/pages/cancellation-reasons';
import CompanyEarings from 'features/reports/pages/company-earing-pdf';
import RegisteredCustomers from 'features/reports/pages/registered-customers';
import RegisteredDrivers from 'features/reports/pages/registered-drivers';
import Report from 'features/reports/pages/report';
import RequestReport from 'features/reports/pages/request';
import RequestLandingPage from 'features/requests/pages/landing-page';
import Requests from 'features/requests/pages/requests';
import RequestList from 'features/requests/pages/requests-list';
import NewVehicleCategory from 'features/settings/container/new-vehicle-category';
import Configuration from 'features/settings/pages/configuration';
import VehicleCategoryConfiguration from 'features/settings/pages/vehicle-category';
import VehicleCategoryDetail from 'features/settings/pages/vehicle-category-detail';
import VehicleCategoryList from 'features/settings/pages/vehicle-category-list';
import NewUser from 'features/users/container/new-user';
import User from 'features/users/pages/user';
import UserDetail from 'features/users/pages/user-detail';
import UserList from 'features/users/pages/user-list';
import NewVehicle from 'features/vehicle/container/new-vehicle';
import VehicleDetail from 'features/vehicle/pages/vehicle-detail';
import VehicleList from 'features/vehicle/pages/vehicle-list';
import VehicleOwnerList from 'features/vehicle/pages/vehicle-owner-list';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ForgetPasswordForm from 'services/auth/component/forget-password';
import LoginForm from 'services/auth/component/lofin-form';
import ResetPasswordForm from 'services/auth/component/reset-password';
import { PrivateRoute } from 'services/auth/Private-route';
import Error404 from 'shared/component/error404';

// Features Pages
import Dashboard from './features/dashboard/pages/dashboard';
import Notification from './features/notification/pages/notification';
import Owner from './features/owner/pages/owner';
import NewProvider from './features/provider/continer/new-provider';
import Provider from './features/provider/pages/provider';
import ProviderDetail from './features/provider/pages/provider-detail';
import ProviderList from './features/provider/pages/provider-list';
import ProviderTracking from './features/provider_tracking/pages/provider_tracking';
import Services from './features/services/pages/services';
import PromoCodes from './features/settings/pages/promo_codes';
import PushNotification from './features/settings/pages/push_notification';
import Vehicle from './features/vehicle/pages/vehicle';
import DeliveryRequestsList from 'features/delivery-requests/pages/delivery-requests-list';
import DeliveryRequestDetail from 'features/delivery-requests/pages/delivery-request-detail';
import Delivery from 'features/delivery-requests/pages/delivery';
import NewDelivery from 'features/delivery-requests/pages/new-delivery';
import OnlineProviders from 'features/online-providers/pages/online-peoviders';
import OnlineProvidersList from 'features/online-providers/pages/online-providers-list';

function AppRoutes() {
    return (
        <Routes>
            <Route path='reset-password' element={<ResetPasswordForm />} />
            <Route path='login' element={<LoginForm />} />
            <Route path='forget-password' element={<ForgetPasswordForm />} />

            {/* dashboard route*/}
            <Route
                index
                element={
                    <PrivateRoute
                        roles={[
                            ROLES.Admin,
                            ROLES.SuperAdmin,
                            ROLES.BankTeller,
                            ROLES.FinanceOfficer,
                            ROLES.Operator,
                        ]}
                        component={Dashboard}
                    />
                }
            />

            <Route
                path='/dashboard'
                element={
                    <PrivateRoute
                        roles={[
                            ROLES.Admin,
                            ROLES.SuperAdmin,
                            ROLES.BankTeller,
                            ROLES.FinanceOfficer,
                            ROLES.Operator,
                        ]}
                        component={Dashboard}
                    />
                }
            />
            <Route
                path='/delivery-requests'
                element={
                    <PrivateRoute
                        roles={[ ROLES.SuperAdmin, ROLES.Admin, ROLES.Operator,]}
                        component={Delivery}
                    />
                }
            >
                 
                <Route index element={<DeliveryRequestsList />} />
                <Route path='/delivery-requests/new' element={<NewDelivery />} />
                <Route path='/delivery-requests/detail/:id' element={<DeliveryRequestDetail />} />
            </Route>
            <Route
                path='/online-providers'
                element={
                    <PrivateRoute
                        roles={[ ROLES.SuperAdmin, ROLES.Admin, ROLES.Operator,]}
                        component={OnlineProviders}
                    />
                }
            >
                 
                <Route index element={<OnlineProvidersList />} />
                {/* <Route path='/delivery-requests/new' element={<NewDelivery />} />
                <Route path='/delivery-requests/detail/:id' element={<DeliveryRequestDetail />} /> */}
            </Route>
            {/*Registration owner route */}
            <Route
                path='/owner'
                element={
                    <PrivateRoute
                        roles={[ROLES.Admin, ROLES.SuperAdmin]}
                        component={Owner}
                    />
                }
            >
                <Route index element={<OwnerList />} />
                <Route path='/owner/new' element={<NewOwner />} />
                <Route path='/owner/detail/:id' element={<OwnerDetail />} />
            </Route>

            {/* users route*/}
            <Route
                path='/users'
                element={
                    <PrivateRoute
                        roles={[ROLES.Admin, ROLES.SuperAdmin]}
                        component={User}
                    />
                }
            >
                <Route index element={<UserList />} />
                <Route path='/users/new' element={<NewUser />} />
                <Route path='/users/detail/:id' element={<UserDetail />} />
            </Route>

            <Route path='/profile' element={<Profile />} />

            {/* public provider route */}
            <Route
                path='/public-driver'
                element={
                    <PrivateRoute
                        roles={[
                            ROLES.Admin,
                            ROLES.SuperAdmin,
                            ROLES.Operator,
                            ROLES.FinanceOfficer,
                        ]}
                        component={PublicProvider}
                    />
                }
            >
                <Route index element={<PublicProviderList />} />
                <Route
                    path='public-driver-detail/:id'
                    element={<PublicProviderLandingPage />}
                />
            </Route>

            {/* public vehicle route  */}
            <Route
                path='/public-vehicle'
                element={
                    <PrivateRoute
                        roles={[ROLES.Admin, ROLES.SuperAdmin, ROLES.Operator]}
                        component={PublicVehicle}
                    />
                }
            >
                <Route index element={<PublicVehicleList />} />
                <Route
                    path='public-vehicle-detail/:id'
                    element={<PublicVehicleLandingPage />}
                />
            </Route>

            {/* driver tracking  */}
            <Route
                path='/driver_tracking'
                element={
                    <PrivateRoute
                        roles={[ROLES.SuperAdmin, ROLES.Operator]}
                        component={ProviderTracking}
                    />
                }
            />

            {/* registration driver route  */}
            <Route
                path='/driver'
                element={
                    <PrivateRoute
                        roles={[ROLES.Admin, ROLES.SuperAdmin]}
                        component={Provider}
                    />
                }
            >
                <Route index element={<ProviderOwnerList />} />
                <Route path='owner-detail/:id' element={<ProviderVehicle />} />
                <Route
                    path='owner-detail/:id/vehicle-detail/:id'
                    element={<ProviderList />}
                />
                <Route
                    path='owner-detail/:id/vehicle-detail/:id/driver-detail/:id'
                    element={<ProviderDetail />}
                />
                <Route
                    path='owner-detail/:id/vehicle-detail/:id/driver/new'
                    element={<NewProvider />}
                />
            </Route>

            {/* customer route  */}
            <Route
                path='/passanger'
                element={
                    <PrivateRoute
                        roles={[ROLES.Admin, ROLES.SuperAdmin, ROLES.Operator]}
                        component={Passanger}
                    />
                }
            >
                <Route index element={<PassangerList />} />
                <Route
                    path='passanger-detail/:id'
                    element={<PassangerLandingPage />}
                />
            </Route>

            {/* registration vehicle route  */}
            <Route
                path='/vehicle'
                element={
                    <PrivateRoute
                        roles={[ROLES.Admin, ROLES.SuperAdmin]}
                        component={Vehicle}
                    />
                }
            >
                <Route index element={<VehicleOwnerList />} />
                <Route
                    path='owner-detail/:id'
                    element={<VehicleList />}
                ></Route>
                <Route
                    path='owner-detail/:id/vehicle/new'
                    element={<NewVehicle />}
                />
                <Route
                    path='owner-detail/:id/vehicle/detail/:id'
                    element={<VehicleDetail />}
                />
            </Route>

            {/* setting route  */}
            <Route
                path='/settings/promo_codes'
                element={
                    <PrivateRoute
                        roles={[ROLES.SuperAdmin, ROLES.Admin]}
                        component={PromoCodes}
                    />
                }
            />

            <Route
                path='/settings/configuration'
                element={
                    <PrivateRoute
                        roles={[ROLES.SuperAdmin, ROLES.Admin]}
                        component={Configuration}
                    />
                }
            />
            <Route
                path='/settings/vehicle-category'
                element={
                    <PrivateRoute
                        roles={[ROLES.SuperAdmin, ROLES.Admin]}
                        component={VehicleCategoryConfiguration}
                    />
                }
            >
                <Route index element={<VehicleCategoryList />} />
                <Route
                    path='/settings/vehicle-category/new'
                    element={<NewVehicleCategory />}
                />
                <Route
                    path='/settings/vehicle-category/detail/:id'
                    element={<VehicleCategoryDetail />}
                />
            </Route>

            {/* feedback route */}
            <Route
                path='/feedback'
                element={
                    <PrivateRoute
                        roles={[ROLES.SuperAdmin, ROLES.Admin, ROLES.Operator]}
                        component={Feedback}
                    />
                }
            >
                <Route index element={<FeedbackList />} />
                <Route
                    path='/feedback/detail/:id'
                    element={<FeedbackDetail />}
                />
            </Route>

            {/* bank route */}
            <Route
                path='/bank-credit'
                element={
                    <PrivateRoute
                        roles={[ROLES.BankTeller, ROLES.SuperAdmin]}
                        component={BankProvider}
                    />
                }
            >
                <Route index element={<BankProviderList />} />
                <Route
                    path='/bank-credit/detail/:id'
                    element={<BankProviderDetail />}
                />
            </Route>

            {/* reports route  */}
            <Route
                path='/reports'
                element={
                    <PrivateRoute
                        roles={[
                            ROLES.SuperAdmin,
                            ROLES.Admin,
                            ROLES.FinanceOfficer,
                        ]}
                        component={Report}
                    />
                }
            >
                <Route
                    path='/reports/company-earing'
                    element={<CompanyEarings />}
                />
                <Route path='/reports/request' element={<RequestReport />} />
                <Route
                    path='/reports/registered-drivers'
                    element={<RegisteredDrivers />}
                />
                <Route
                    path='/reports/registered-customers'
                    element={<RegisteredCustomers />}
                />

                <Route
                    path='/reports/cancellation-reasons'
                    element={<CancellationReasons />}
                />
            </Route>

            {/* award route  */}
            <Route
                path='/award'
                element={
                    <PrivateRoute
                        roles={[
                            ROLES.Admin,
                            ROLES.FinanceOfficer,
                            ROLES.Operator,
                            ROLES.SuperAdmin,
                        ]}
                        component={Award}
                    />
                }
            >
                <Route index element={<AwardList />} />
                <Route path='/award/new' element={<NewAward />} />
                <Route path='/award/detail/:id' element={<AwardDetail />} />
            </Route>

            {/* requests route  */}
            <Route
                path='/requests'
                element={
                    <PrivateRoute
                        roles={[ROLES.Admin, ROLES.SuperAdmin, ROLES.Operator]}
                        component={Requests}
                    />
                }
            >
                <Route index element={<RequestList />} />
                <Route
                    path='/requests/detail/:id'
                    element={<RequestLandingPage />}
                />
            </Route>

            {/* notification route */}
            <Route
                path='/notification'
                element={
                    <PrivateRoute
                        roles={[ROLES.Admin, ROLES.SuperAdmin]}
                        component={Notification}
                    />
                }
            >
                <Route index element={<NotificationList />} />
                <Route
                    path='/notification/detail/:id'
                    element={<NotificationDetail />}
                />
                <Route path='/notification/new' element={<NotificationNew />} />
            </Route>

            {/* emergency report route  */}
            <Route
                path='/emergency-report'
                element={
                    <PrivateRoute
                        roles={[ROLES.Admin, ROLES.SuperAdmin, ROLES.Operator]}
                        component={EmergencyReport}
                    />
                }
            >
                <Route index element={<EmergencyReportList />} />
                <Route
                    path='/emergency-report/detail/:id'
                    element={<EmergencyReportLandingPage />}
                />

            </Route>

            <Route path='*' element={<Error404 />} />
        </Routes>
    );
}

export default AppRoutes;
